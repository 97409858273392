<template>
    <div>
      
      
          
              <div>
                <section id="ContactForm" class="section">
                  <div class="container">
                    <h1 class="main-title">Bize Ulaşın</h1>
                    <b-alert v-if="isSubmitted" show variant="success">{{ $t("yourMessageHasBeenSent") }}</b-alert>
                  <form ref="contactUsForm" @submit.prevent="submitted" autocomplete="off">

                      <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-8 col-md-offset-2">
                         
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label blue" for="nameSurname">{{
                                $t('contactFormLabels.nameSurname')
                            }} *</label>
                            <input type="text" id="nameSurname" class="form-control col-sm-8" v-model="userData.nameSurname"
                              :class="{ errror: $v.userData.nameSurname.$error }" @blur="$v.userData.nameSurname.$touch()">
                          </div>
                          <div v-if="$v.userData.nameSurname.$error">
                            <p v-if="!$v.userData.nameSurname.required" class="text-danger mb-3">{{
                                $t('contactFormLabels.nameSurnameRequired')
                            }}</p>
                          </div>
  
                          
  
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label blue" for="phoneNumber">{{
                                $t('contactFormLabels.phoneNumber')
                            }} *</label>
                            <input type="number" id="phoneNumber" class="form-control col-sm-8"
                              v-model="userData.phoneNumber" :class="{ errror: $v.userData.phoneNumber.$error }"
                              @blur="$v.userData.phoneNumber.$touch()">
                          </div>
                          <div v-if="$v.userData.phoneNumber.$error">
                            <p v-if="!$v.userData.phoneNumber.required" class="text-danger mb-3">{{
                                $t('contactFormLabels.phoneNumberRequired')
                            }}</p>
                          </div>
  
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label blue" for="email">{{ $t('contactFormLabels.email') }}
                              *</label>
                            <input type="text" id="email" class="form-control col-sm-8" v-model="userData.email"
                              :class="{ errror: $v.userData.email.$error }" @blur="$v.userData.email.$touch()">
                          </div>
                          <div v-if="$v.userData.email.$error">
                            <p v-if="!$v.userData.email.email" class="text-danger mb-3">{{
                                $t('contactFormLabels.emailValid')
                            }}</p>
                            <p v-if="!$v.userData.email.required" class="text-danger mb-3">{{
                                $t('contactFormLabels.emailRequired')
                            }}</p>
                          </div>
                     
  
                          <div class="row">
                            <div class="col-xs-12 col-sm-12 col-md-8 col-md-offset-2">
                              <div class="col"></div>
                              <div class="col-xs-12 col-md-8 offset-md-4">
                                <!--mine  -->
                                <vue-recaptcha ref="invisibleRecaptcha" @verify="onVerify" @expired="onExpired"
                                  size="invisible" :sitekey="this.sitekey">
                                </vue-recaptcha>
                              </div>
                            </div>
  
                          </div>
  
  
                        </div>
                      </div>
  
                      <div class="row">
                      <div class="col-xs-12 col-sm-12 col-md-8 col-md-offset-2">
                        <div class="col"></div>
                        <div class="col-xs-12 col-md-8 offset-md-4" style="display:flex;">
                          <button :disabled="$v.$invalid" class="btn btn-primary" @click.prevent="submitted">{{
                              $t('contactFormLabels.contactUsButton')
                          }}</button> 
                          <button class="btn btn-reset" @click.prevent="resetForm">Temizle</button>
                        </div>
                        <div v-if="$v.$anyError" class="text-danger text-center mb-3 mt-3">{{
                            $t('contactFormLabels.fillRequiredFields')
                        }}</div>
                      </div>
                    </div>
                    </form>
                    <hr>
                    <p><small>{{ $t('contactFormLabels.requiredFields') }}</small></p>
                    <p><small>{{ $t('contactFormLabels.note') }}</small></p>
  
                  </div>
  
                </section>
  
              </div>
           
  
     
    </div>
  </template>
  <script>
  import axios from 'axios';
  
  /* mine*/
  import VueRecaptcha from 'vue-recaptcha';
  import { required, email } from 'vuelidate/lib/validators';
  
  export default {
    data() {
      return {
        sitekey: '6LeHSqYZAAAAAIABXNPEW24txMYUSBwLEyVWjTri',
        secretkey: '6LeHSqYZAAAAAPiF8FaHJ3V59toGYd2ltMeXBd8D',
        pageSlug: this.$route.params.slug,
        errors: [],
        FormResult: null,
        userData: {
          email: this.$route.params.ffemail,
          nameSurname: this.$route.params.ffname,
          phoneNumber: ''
        },
        isSubmitted: false
      }
    },
    head: {
      script: [
        { type: 'text/javascript', src: 'https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit', async: true, body: true },
        { t: 'application/ld+json', i: '{ "@context": "http://schema.org" }' }
      ]
    },
    components: {
      VueRecaptcha
    },
    validations: {
      userData: {
        email: {
          required,
          email
        },
        nameSurname: {
          required
        },
        phoneNumber: {
          required
        }
      }
      
    },
    methods: {
      submitted(e) {
        this.isSubmitted = true;
        e.preventDefault();
        this.$refs.invisibleRecaptcha.execute();
      },
      onVerify: function (recaptchaToken) {
        this.resetForm();
        this.resetRecaptcha();
  
        let config = {
          headers: {
            "Authorization": "Token 3f1c945dda80e43cd5683c8443092787",
            "Content-Type": "application/json"
          }
        }
  
        let myData = {
          content:
            'EMail' + '=' + this.userData.email + '&'
            + 'NameSurname' + '=' + this.userData.nameSurname + '&'
            + 'PhoneNumber' + '=' + this.userData.phoneNumber,
          application: this.$application,
          language: this.$t("languageCode"),
          dynamicField: '647eef1298a85a0464c71658'
        };
  
        let secondData = JSON.stringify(myData);
        let successMessage = this.$t('yourMessageHasBeenSent');
        let errorMessage = this.$t('errorMessage');
  
        axios.post('https://recaptcha-verifier.tavitlab.com', {
          siteKey: this.sitekey,
          captchaToken: recaptchaToken
        })
          .then(function (response) {
            if (response.data.result == true) {
  
              axios.post('https://api.tavguvenlik.com/forms', secondData, config)
                .then(response => {
                  if (response.data.result == true) {
                    alert(successMessage);
                  } else {
                    alert(errorMessage);
                  }
                  //document.location = document.location.href;
                })
                .catch(error => {
                  console.log(error)
                  //alert(errorMessage);
                  //document.location = document.location.href;
                })
  
            } else {
             // document.location = document.location.href;
            }
          })
          .catch(function (error) {
            console.log(error);
            //document.location = document.location.href;
          });
          this.resetForm();
      },
      onExpired: function () {
      this.resetRecaptcha();
    
    },
    resetRecaptcha() {
      this.$refs.invisibleRecaptcha.reset();
    },
    resetForm() {
          this.$refs.contactUsForm.reset();
    }
    }
  }
  </script>
  